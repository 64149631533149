import axios from 'axios';
// import { Auth } from 'aws-amplify';
import { getIdTokenFromCookie } from '../services/cookie-storage-service';

let tenantIdentityServiceUrl = process.env.GATSBY_TENANT_IDENTITY_URL;

// const getToken = async () => {
//     const currentSession = await Auth.currentSession();
//     return currentSession.getIdToken().getJwtToken();
// }

const getPoolInformation = async (tenantGuid) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/getTenantByGuid/${tenantGuid}`;

    let response = await axios.get(apiURL);
    return response.data.dataObject[0];
}

const getUserDetails = async (email) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/getUserDetailsByEmailId/?emailId=${email}`;

    let response = await axios.get(apiURL);
    return response.data.dataObject;
}

const createNewSubscription = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/createNewSubscription`;

    let response = await axios.post(apiURL, inputData);
    return response;
}

const resendOTP = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/resendConfirmationCode`;

    let response = await axios.post(apiURL, inputData);
    return response;
}

const confirmRegistration = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/confirmRegistration`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const buyLicense = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/Purchase`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const createTrialUser = async (inputData) => {
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/createTrialUser`;
    let response = await axios.post(url, inputData);
    return response;
}

const updateTenantAdminPassword = async (inputData) => {
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/updateTenantAdminPassword`;
    let response = await axios.put(url, inputData);
    return response;
}

const updateUserStatus = async (inputData) => {
    let token = await getIdTokenFromCookie();
    let headerOptions = {
        headers: {
            'Authorization': token
        }
    }
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/updateUserStatus`;
    let response = await axios.put(url, inputData, headerOptions);
    return response.data;
}

const getLoginUserPoolInformation = async (email) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/getLoginUserPoolInformation/?email=${email}`;

    return new Promise(function (resolve, reject) {
        axios.get(apiURL)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

const getRegisteredUserByEmail = async (email) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/getRegisteredUserByEmail/?email=${email}`;

    return new Promise(function (resolve, reject) {
        axios.get(apiURL)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

const registerTrialUser = async (inputData) => {
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/registerTrialUser`;
    let response = await axios.post(url, inputData);
    return response;
}

const addNewRegisteredUser = async (inputData) => {
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/addNewRegisteredUser`;
    let response = await axios.post(url, inputData);
    return response;
}

const resendTemporaryPasssword = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/resendTemporaryPasswordWithoutLogin`;
    let response = await axios.post(apiURL, inputData);
    return response.data;
}

const confirmTrialUserRegistration = async (inputData) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/confirmTrialUserRegistration`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const createTrialUserInfo = async (inputData) => {
    let url = `${tenantIdentityServiceUrl}/tenantIdentity/createTrialFreeInfo`;
    let response = await axios.post(url, inputData);
    return response;
}

// DELETE => terminate user session
const terminateUserSession = async (sessionGuid) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/terminateUserSession/?sessionGuid=${sessionGuid}&isSignOutRequired=false`;
    let response = await axios.delete(apiURL);
    return response;
}

// PUT => refresh user session time
const refreshUserSessionTime = async (sessionGuid) => {
    let apiURL = `${tenantIdentityServiceUrl}/tenantIdentity/refreshUserSessionTime/?sessionGuid=${sessionGuid}`;
    let response = await axios.put(apiURL);
    return response;
}

export {
    getPoolInformation,
    getUserDetails,
    createNewSubscription,
    resendOTP,
    confirmRegistration,
    createTrialUser,
    buyLicense,
    updateTenantAdminPassword,
    getLoginUserPoolInformation,
    getRegisteredUserByEmail,
    addNewRegisteredUser,
    updateUserStatus,
    resendTemporaryPasssword,
    confirmTrialUserRegistration,
    registerTrialUser,
    createTrialUserInfo,
    terminateUserSession,
    refreshUserSessionTime,
}