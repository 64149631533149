// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-common-resources-test-js": () => import("./../../../src/components/common/ResourcesTest.js" /* webpackChunkName: "component---src-components-common-resources-test-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-credentialspage-js": () => import("./../../../src/pages/credentialspage.js" /* webpackChunkName: "component---src-pages-credentialspage-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-emailverification-js": () => import("./../../../src/pages/emailverification.js" /* webpackChunkName: "component---src-pages-emailverification-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-free-demo-js": () => import("./../../../src/pages/free-demo.js" /* webpackChunkName: "component---src-pages-free-demo-js" */),
  "component---src-pages-free-trial-js": () => import("./../../../src/pages/free-trial.js" /* webpackChunkName: "component---src-pages-free-trial-js" */),
  "component---src-pages-free-trial-old-code-backup-js": () => import("./../../../src/pages/free-trial-old-code-backup.js" /* webpackChunkName: "component---src-pages-free-trial-old-code-backup-js" */),
  "component---src-pages-free-trial-old-js": () => import("./../../../src/pages/free-trial-old.js" /* webpackChunkName: "component---src-pages-free-trial-old-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-otp-verification-js": () => import("./../../../src/pages/otp-verification.js" /* webpackChunkName: "component---src-pages-otp-verification-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-air-freight-software-js": () => import("./../../../src/pages/product/air-freight-software.js" /* webpackChunkName: "component---src-pages-product-air-freight-software-js" */),
  "component---src-pages-product-customs-clearance-software-js": () => import("./../../../src/pages/product/customs-clearance-software.js" /* webpackChunkName: "component---src-pages-product-customs-clearance-software-js" */),
  "component---src-pages-product-freight-forwarding-sofware-js": () => import("./../../../src/pages/product/freight-forwarding-sofware.js" /* webpackChunkName: "component---src-pages-product-freight-forwarding-sofware-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-land-freight-software-js": () => import("./../../../src/pages/product/land-freight-software.js" /* webpackChunkName: "component---src-pages-product-land-freight-software-js" */),
  "component---src-pages-product-nvocc-software-js": () => import("./../../../src/pages/product/nvocc-software.js" /* webpackChunkName: "component---src-pages-product-nvocc-software-js" */),
  "component---src-pages-product-ocean-freight-software-js": () => import("./../../../src/pages/product/ocean-freight-software.js" /* webpackChunkName: "component---src-pages-product-ocean-freight-software-js" */),
  "component---src-pages-product-warehouse-management-software-js": () => import("./../../../src/pages/product/warehouse-management-software.js" /* webpackChunkName: "component---src-pages-product-warehouse-management-software-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-pages-purchase-new-message-code-js": () => import("./../../../src/pages/purchase-new-message-code.js" /* webpackChunkName: "component---src-pages-purchase-new-message-code-js" */),
  "component---src-pages-purchase-old-code-backup-js": () => import("./../../../src/pages/purchase-old-code-backup.js" /* webpackChunkName: "component---src-pages-purchase-old-code-backup-js" */),
  "component---src-pages-purchase-old-payment-code-js": () => import("./../../../src/pages/purchase-old-payment-code.js" /* webpackChunkName: "component---src-pages-purchase-old-payment-code-js" */),
  "component---src-pages-signinsuccesspage-js": () => import("./../../../src/pages/signinsuccesspage.js" /* webpackChunkName: "component---src-pages-signinsuccesspage-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-trialcredentialpage-js": () => import("./../../../src/pages/trialcredentialpage.js" /* webpackChunkName: "component---src-pages-trialcredentialpage-js" */),
  "component---src-templates-design-2-js": () => import("./../../../src/templates/design2.js" /* webpackChunkName: "component---src-templates-design-2-js" */)
}

