import axios from 'axios';
// import { Auth } from 'aws-amplify';
import { getIdTokenFromCookie } from '../services/cookie-storage-service';

let trialTenantIdentityServiceUrl = process.env.GATSBY_TRIAL_TENANT_IDENTITY_URL;

// const getToken = async () => {
//     const currentSession = await Auth.currentSession();
//     return currentSession.getIdToken().getJwtToken();
// }

const createTrialUser = async (inputData) => {
    let url = `${trialTenantIdentityServiceUrl}/trialTenantIdentity/createTrialUser`;
    let response = await axios.post(url, inputData);
    return response;
}

const verifyOTP = async (inputData) => {
    let apiURL = `${trialTenantIdentityServiceUrl}/trialTenantIdentity/verifyOTP`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const resendOTP = async (inputData) => {
    let apiURL = `${trialTenantIdentityServiceUrl}/trialTenantIdentity/resendConfirmationCode`;

    let response = await axios.post(apiURL, inputData);
    return response;
}

const updateTenantAdminPassword = async (inputData) => {
    let url = `${trialTenantIdentityServiceUrl}/trialTenantIdentity/updateTenantAdminPassword`;
    let response = await axios.put(url, inputData);
    return response;
}

const getTrialUserPoolInformation = async (email) => {
    let apiURL = `${trialTenantIdentityServiceUrl}/tenantIdentity/getLoginUserPoolInformation/?email=${email}`;

    return new Promise(function (resolve, reject) {
        axios.get(apiURL)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

const getTrialPoolInformation = async (tenantGuid) => {
    let apiURL = `${trialTenantIdentityServiceUrl}/tenantIdentity/getTenantByGuid/${tenantGuid}`;

    let response = await axios.get(apiURL);
    return response.data.dataObject[0];
}

const updateTrialUserStatus = async (inputData) => {
    let token = await getIdTokenFromCookie();
    let headerOptions = {
        headers: {
            'Authorization': token
        }
    }
    let url = `${trialTenantIdentityServiceUrl}/tenantIdentity/updateUserStatus`;
    let response = await axios.put(url, inputData, headerOptions);
    return response.data;
}

const resendTemporaryPassswordForTrialUser = async (inputData) => {
    let apiURL = `${trialTenantIdentityServiceUrl}/tenantIdentity/resendTemporaryPasswordWithoutLogin`;
    let response = await axios.post(apiURL, inputData);
    return response.data;
}

export {
    resendOTP,
    verifyOTP,
    createTrialUser,
    updateTenantAdminPassword,
    getTrialUserPoolInformation,
    getTrialPoolInformation,
    updateTrialUserStatus,
    resendTemporaryPassswordForTrialUser
}