/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { Auth, Amplify } from "aws-amplify";
import awsPoolConfiguration from "./src/aws-exports";
import { getIdTokenFromCookie, getTokenDetailsByDecoding } from './src/services/cookie-storage-service';
import { getPoolInformation } from './src/services/tenant-identity-service';
import { getTrialPoolInformation } from './src/services/trial-tenant-identity-service';

const configureUserPool = async () => {
    let idTokenFromCookie = await getIdTokenFromCookie();
    let poolResult = [];

    if (idTokenFromCookie !== "" && idTokenFromCookie !== undefined && idTokenFromCookie !== null) {
        let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);

        if (tokenDetails !== undefined && tokenDetails !== null) {
            if (tokenDetails.isTrialUser !== undefined && tokenDetails.isTrialUser === "YES") {
                poolResult = await getTrialPoolInformation(tokenDetails.tenantGuid);
            } else {
                poolResult = await getPoolInformation(tokenDetails.tenantGuid);
            }

            if (poolResult !== null && poolResult !== undefined && Object.keys(poolResult).length > 0) {
                awsPoolConfiguration.aws_project_region = poolResult.region;
                awsPoolConfiguration.aws_cognito_identity_pool_id = poolResult.identityPoolId;
                awsPoolConfiguration.aws_cognito_region = poolResult.region;
                awsPoolConfiguration.aws_user_pools_id = poolResult.userPoolId;
                awsPoolConfiguration.aws_user_pools_web_client_id = poolResult.clientId;

                Amplify.configure(awsPoolConfiguration);
                Auth.configure(awsPoolConfiguration);
            }
        }
    }
}

configureUserPool();



