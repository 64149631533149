import { CustomStorage } from './custom-storage';

let domainName = process.env.GATSBY_DOMAIN;

const awsPoolConfiguration = {
    "aws_project_region": "",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "",
    "aws_user_pools_id": "",
    "aws_user_pools_web_client_id": "",
    // "oauth": {},
    // 'aws_appsync_authenticationType': 'API_KEY',
    // 'cookieStorage': {
    //     'domain': domainName,
    //     // domain: '.amplifyapp.com',
    //     // path: '/',
    //     // expires: 365,
    //     // sameSite: "strict" | "lax",
    //     'secure': true
    // },
    "storage": CustomStorage
};

export default awsPoolConfiguration;
